import { Message, MessageBox } from "element-ui";
import {NO_INTERNET_MESSAGE, SERVER_ERROR_MESSAGE} from "@/services/messageService";
import {store} from "@/store";

export const GlobalOpenImage = (url) => {
  // Membuka URL dalam tab baru
  window.open(url, "_blank");
};

export const GlobalHandleErrorApi = (errorMessage, returnType = "array") => {
  if (errorMessage) {
    let sequence = 1;
    let message = [];
    let messageString = [];

    for (const [key, value] of Object.entries(errorMessage)) {
      let alertTemp = {
        index: sequence,
        key: key,
        value: returnType === "object" ? value : value[0],
      };
      message.push(alertTemp);
      messageString.push(value[0]);
    }
    if (returnType === "string") {
      return messageString.join();
    }
    return message;
  }
  return "Opps! Ada kesalahan";
};

// global function message! hati2 untuk merubah pesan disini karena sudah dipakai ini
export const HandlerNotification = (jenis = null, message = null) => {
  if (!jenis) {
    Message({
      type: "success",
      message: message || "Berhasil Diproses!",
    });
    return;
  }
  if (jenis >= 500) {
    Message({
      type: "error",
      message: SERVER_ERROR_MESSAGE,
    });
    return;
  }
  if (jenis === "id") {
    Message({
      type: "error",
      message:
        message ||
        "ID tidak ditemukan, Ulangi Lagi dari awal dan Refresh Halaman",
    });
    return;
  }
  if (jenis === "permanen") {
    Message({
      type: "error",
      message:
        message ||
        "Data sudah digunakan. Untuk proses hapus secara permanen tidak di perbolehkan.",
    });
    return;
  }
  if (jenis === "notAkses") {
    Message({
      type: "info",
      message:
        message ||
        "Produk Berlangganan Tidak Aktif / Level anda tidak ada Akses untuk melakukan perintah ini!",
    });
    return;
  }
  if (jenis === "error") {
    Message({
      type: "error",
      message: message || "Jaringan Bermasalah!",
    });
  } else if (jenis === "info") {
    Message({
      type: "info",
      message: message || "Informasi NULL!",
    });
  } else if (jenis === "cors") {
    Message({
      type: "error",
      message: message || "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi",
    });
  } else {
    Message({
      type: "error",
      message: message || "Server Error",
    });
  }
};
export const HandlerResult = (result = null, message = null) => {
  if (result.data === "") {
    Message({
      type: "error",
      message: `Response ${message} Tidak Ditemukan. Ini disebabkan ketika mengambil data ke server, server tidak mengirim response apapun.`,
    });
    return false;
  }
  return true;
};
export const HandlerPageStartEnd = (
  currentpage = 1,
  dataTotal = 0,
  limit = 10,
) => {
  if (currentpage === 1) return 1;
  if (!limit) return 1;
  if (!dataTotal || dataTotal === 1) return currentpage - 1;
  return currentpage;
};
export const HandlerAlertUnauthorized = () => {
  MessageBox.alert(
    `Login sudah <strong>kadalwarsa</strong> atau <strong>Akun ini sudah di non akatifkan</strong>. Anda akan diarahkan ke halaman LOGIN.`,
    "PEMBERITAHUAN",
    {
      closeOnClickModal: true,
      closeOnPressEscape: true,
      confirmButtonText: "OK",
      dangerouslyUseHTMLString: true,
      callback: function () {
        localStorage.removeItem("storediskola");
        window.location = "/login";
      },
    },
  );
  return true;
};



export const HandlerMessageServerError = () => {
	MessageBox.alert(
		SERVER_ERROR_MESSAGE,
		"PEMBERITAHUAN",
		{
			closeOnClickModal: true,
			closeOnPressEscape: true,
			confirmButtonText: "OK",
			dangerouslyUseHTMLString: true,
		}
	);
}

export const HandlerMessageNoInternet = () => {
	MessageBox.alert(
		NO_INTERNET_MESSAGE,
		"PEMBERITAHUAN",
		{
			closeOnClickModal: true,
			closeOnPressEscape: true,
			confirmButtonText: "OK",
			dangerouslyUseHTMLString: true,
		}
	);
}

export const ShowAlertIsServerErrorOrNoInternet = (errorStrukturInternal) => {
	if (errorStrukturInternal.status >= 500) {
		HandlerMessageServerError();
	}

	if (errorStrukturInternal.code === "ERR_NETWORK") {
		HandlerMessageNoInternet();
	}
}

export const isServerErrorOrNoInternet = (errorStrukturInternal) => {
	if (errorStrukturInternal.status >= 500) {
		return true
	}

	if (errorStrukturInternal.code === "ERR_NETWORK") {
		return true
	}

	return false
}

export const getThumbnailVideo = async (datases) => {
	try {
		// Gunakan Promise.all untuk menunggu semua thumbnail dihasilkan
		const thumbnailPromises = datases
			.map(async (item) => {
				const thumbnail = await getThumbnailFromVideoURL(item.content_path, 1);
				store.commit("kioskStore/UPDATE_LIST_THUMBNAIL", {
					key: item.content_path,
					value: thumbnail,
				});
			});

		// Tunggu hingga semua thumbnail dihasilkan
		await Promise.all(thumbnailPromises);
	} catch (error) {
		console.error("Error while generating thumbnails:", error);
	}
}

export const getThumbnailFromVideoURL = (videoURL, secondThumbnail = 1) => {
	return new Promise((resolve, reject) => {
		const video = document.createElement("video");
		video.src = videoURL;
		video.crossOrigin = "anonymous"; // Pastikan CORS diaktifkan jika video berada di server lain

		video.onloadedmetadata = () => {
			// Pastikan durasi video lebih panjang dari secondThumbnail
			if (video.duration < secondThumbnail) {
				secondThumbnail = video.duration;
			}
			video.currentTime = secondThumbnail;
		};

		video.ontimeupdate = () => {
			if (video.currentTime >= secondThumbnail) {
				const canvas = document.createElement("canvas");
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				const ctx = canvas.getContext("2d");
				ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
				const thumbnail = canvas.toDataURL("image/png");
				resolve(thumbnail);
				video.ontimeupdate = null; // Hentikan pembaruan waktu untuk mencegah callback terus dipanggil
			}
		};

		video.onerror = (error) => {
			reject("Gagal memuat video atau membuat thumbnail.", error);
		};
	});
}
