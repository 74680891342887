import repository from "@/repositories/KioskRepository";
import { ShowAlertIsServerErrorOrNoInternet } from "@/utils/Helpers";
import StrukturErrorService from "@/services/StrukturErrorService";

export default class KioskService {
  constructor() {
    this.dataRepository = repository;
  }

  async listSchoolsGet() {
    try {
      return await this.dataRepository.getListSchools({ limit: 1050, page: 1 });
    } catch (error) {
      const parsedError = StrukturErrorService.parseError(error);
      throw parsedError;
    }
  }

  async contentGet(payload) {
    const { limit, page, filterQ } = payload;
    try {
      const params = {
        limit: limit ?? 0,
        page: page || undefined,
        q: filterQ || undefined,
      };
      return await this.dataRepository.getContentImage(params);
    } catch (error) {
      const parsedError = StrukturErrorService.parseError(error);
      throw parsedError;
    }
  }

  async contentImagePost(payload) {
    const { file, schoolIds, schoolType, destinationType } = payload;
    if (!destinationType) {
      throw new Error("destinationType must be provided");
    }
    if (!file) {
      throw new Error("file must be provided");
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      if (destinationType === "schools") {
        if (Array.isArray(schoolIds) && schoolIds.length > 0) {
          // buat foreach untuk key school_id[]
					schoolIds.forEach((image, index) => {
						formData.append(`school_id[${index}]`, image);
					});
        } else {
          throw new Error("schoolIds must be provided");
        }
      } else {
        if (schoolType) {
          formData.append("school_type", schoolType);
        }
      }

      return await this.dataRepository.postContentImage(formData);
    } catch (error) {
      ShowAlertIsServerErrorOrNoInternet(error);

      const parsedError = StrukturErrorService.parseError(error);
      throw parsedError;
    }
  }

  async contentVideoPost(payload) {
    const { file, schoolIds, schoolType, destinationType } = payload;
    if (!destinationType) {
      throw new Error("destinationType must be provided");
    }
    if (!file) {
      throw new Error("file must be provided");
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      if (destinationType === "schools") {
        if (Array.isArray(schoolIds) && schoolIds.length > 0) {
          // buat foreach untuk key school_id[]
					schoolIds.forEach((image, index) => {
						formData.append(`school_id[${index}]`, image);
					});
        } else {
          throw new Error("schoolIds must be provided");
        }
      } else {
        if (schoolType) {
          formData.append("school_type", schoolType);
        }
      }

      return await this.dataRepository.postContentVideo(formData);
    } catch (error) {
      ShowAlertIsServerErrorOrNoInternet(error);

      const parsedError = StrukturErrorService.parseError(error);
      throw parsedError;
    }
  }

  async contentVideoGet(payload) {
    const { limit, page, filterQ } = payload;
    try {
      const params = {
        limit: limit ?? 0,
        page: page || undefined,
        q: filterQ || undefined,
      };
      return await this.dataRepository.getContentVideo(params);
    } catch (error) {
      const parsedError = StrukturErrorService.parseError(error);
      throw parsedError;
    }
  }

  async deleteContent(id) {
    if (!id) {
      throw new Error("ID required");
    }

    try {
      return await this.dataRepository.deleteContent(id);
    } catch (error) {
      ShowAlertIsServerErrorOrNoInternet(error);

      const parsedError = StrukturErrorService.parseError(error);
      throw parsedError;
    }
  }
}
