import axios from "axios";
import ApiService from "@/utils/ApiService";

class KioskRepository {
	constructor() {
		this.apiService = ApiService;
		this.cancelTokenSource = null;
		this.mock = 'http://localhost:3000/api/government';
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getListSchools(params) {
		return await this.apiService.get(`/kiosk/list-school`, { params });
	}

	/**
	 * IMAGE
	 * */
	async getContentImage(params) {
		return await this.apiService.get(`/kiosk/content/image`, { params });
	}
	async postContentImage(formData) {
		//const config = {}
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		}
		return await this.apiService.post(`/kiosk/upload-image`, formData, config);
	}

	/**
	 * IMAGE
	 * */
	async getContentVideo(params) {
		return await this.apiService.get(`/kiosk/content/video`, { params });
	}
	async postContentVideo(formData) {
		//const config = {}
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		}
		return await this.apiService.post(`/kiosk/upload-video`, formData, config);
	}

	async deleteContent(id) {
		return await this.apiService.delete(`/kiosk/content/${id}/delete`);
	}

}

export default new KioskRepository();
