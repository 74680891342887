import { Message } from "element-ui";
import Swal from "sweetalert2";
import {
  DUPLICATE_CALL_SERVER_MESSAGE,
  SERVER_ERROR_MESSAGE,
} from "@/services/messageService";
import KioskService from "@/services/KioskService";
import { getThumbnailVideo, isServerErrorOrNoInternet } from "@/utils/Helpers";

const state = {
  data: {
    items: [],
    itemsErrorMessage: null,
    limit: 10,
    page: 1,
    total: 0,
    loading: false,
    showmodal: false,
    formData: {
      file: null,
      destinationType: "schools",
      schoolType: "SMA",
    },
    formDataError: "error form data",
    selectedIds: [],
    listSchool: {
      page: 1,
      lastPage: 1,
      limit: 10,
      items: [],
      loading: false,
      itemsErrorMessage: null,
    },
    contentVideo: {
			selectedIds: [],
      listThumbnailObject: {},
      items: [],
      showmodal: false,
      loading: false,
      itemsErrorMessage: null,
			formData: {
				file: null,
				destinationType: "schools",
				schoolType: "SMA",
			},
    },
  },
};

const mutations = {
  UPDATE_LIST_THUMBNAIL(state, payload) {
    state.data.contentVideo.listThumbnailObject[payload.key] = payload.value;
  },

  changeKioskListSchools(state, payload) {
    state.data.listSchool = Object.assign({}, state.data.listSchool, payload);
  },
  changeListSchoolsLoading(state, payload) {
    state.data.listSchool.loading = payload;
  },

  changeKioskContentVideo(state, payload) {
    state.data.contentVideo = Object.assign(
      {},
      state.data.contentVideo,
      payload
    );
  },
  changeContentVideoLoading(state, payload) {
    state.data.contentVideo.loading = payload;
  },

  changeKiosk(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  ADD_ITEMS(state, payload) {
    state.data.items.push(payload);
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  ADD_ITEMS_VIDEO(state, payload) {
    state.data.contentVideo.items.push(payload);
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Number} id - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  DELETE_ITEMS_VIDEO(state, id) {
    const index = state.data.contentVideo.items.findIndex(
      (item) => item.id === id
    );
    if (index !== -1) {
      state.data.contentVideo.items.splice(index, 1);
    }
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Number} id - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  DELETE_ITEMS(state, id) {
    const index = state.data.items.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.data.items.splice(index, 1);
    }
  },

};

const actions = {
  async fetchListSchools({ commit, state }) {
    const { loading } = state.data.listSchool;

    if (loading) {
      console.log(DUPLICATE_CALL_SERVER_MESSAGE);
      return false;
    }

    commit("changeListSchoolsLoading", true);

    try {
      const kioskService = new KioskService();
      const result = await kioskService.listSchoolsGet();
      commit("changeKioskListSchools", {
        items: result?.data?.data || [],
        total: result?.data?.data.length || 0,
        itemsErrorMessage: null,
      });
      return true;
    } catch (e) {
      console.log(e);
      commit("changeKioskListSchools", {
        items: [],
        total: 0,
        itemsErrorMessage: e.error,
      });

      if (isServerErrorOrNoInternet(e)) {
        commit("changeKioskListSchools", {
          itemsErrorMessage: SERVER_ERROR_MESSAGE,
        });
        return false;
      }

      Swal.fire("Error", e.error, "error");
      return false;
    } finally {
      commit("changeListSchoolsLoading", false);
    }
  },

  async fetchListContent({ commit, state }) {
    const { limit, page, loading } = state.data;

    if (loading) {
      console.log(DUPLICATE_CALL_SERVER_MESSAGE);
      return false;
    }

    commit("changeLoading", true);
    const params = {
      limit: limit,
      page: page,
    };

    try {
      const kioskService = new KioskService();
      const result = await kioskService.contentGet(params);
      commit("changeKiosk", {
        items: result?.data?.data || [],
        total: result?.data?.data.length || 0,
        itemsErrorMessage: null,
      });
      return true;
    } catch (e) {
      console.log(e);
      commit("changeKiosk", {
        items: [],
        total: 0,
        page: 1,
        itemsErrorMessage: e.error,
      });

      if (isServerErrorOrNoInternet(e)) {
        return false;
      }

      Swal.fire("Error", e.error, "error");
      return false;
    } finally {
      commit("changeLoading", false);
    }
  },

  async postContentImage({ commit, state, dispatch }) {
    const formData = {
      file: state.data.formData.file,
      schoolIds: state.data.selectedIds,
      schoolType: state.data.formData.schoolType,
      destinationType: state.data.formData.destinationType,
    };

    try {
      const kioskService = new KioskService();
      await kioskService.contentImagePost(formData);
      commit("changeKiosk", {
        itemsErrorMessage: null,
				selectedIds: [],
				formData: {
					file: null,
					destinationType: "schools",
					schoolType: "SMA",
				}
			})
      dispatch("fetchListContent");
      return true;
    } catch (e) {
      console.log(e);

      if (isServerErrorOrNoInternet(e)) {
        Swal.fire("Error", SERVER_ERROR_MESSAGE, "error");
        return false;
      }

      Swal.fire("Error", e.error, "error");
      return false;
    }
  },

  async fetchListContentVideo({ commit, state }) {
    const { limit, page, loading } = state.data.contentVideo;

    if (loading) {
      console.log(DUPLICATE_CALL_SERVER_MESSAGE);
      return false;
    }

    commit("changeContentVideoLoading", true);
    const params = {
      limit: limit,
      page: page,
    };

    try {
      const kioskService = new KioskService();
      const result = await kioskService.contentVideoGet(params);
      if (result?.data?.data.length) {
        await getThumbnailVideo(result.data.data);
      }
      commit("changeKioskContentVideo", {
        items: result?.data?.data || [],
        itemsErrorMessage: null,
      });
      return true;
    } catch (e) {
      console.log(e);
      commit("changeKioskContentVideo", {
        items: [],
        itemsErrorMessage: e.error,
      });

      if (isServerErrorOrNoInternet(e)) {
        commit("changeKioskContentVideo", {
          itemsErrorMessage: SERVER_ERROR_MESSAGE,
        });
        return false;
      }
      return false;
    } finally {
      commit("changeContentVideoLoading", false);
    }
  },

	async postContentVideo({ state, commit, dispatch }) {
		const formData = {
			file: state.data.contentVideo.formData.file,
			schoolIds: state.data.contentVideo.selectedIds,
			schoolType: state.data.contentVideo.formData.schoolType,
			destinationType: state.data.contentVideo.formData.destinationType,
		};

		try {
			const kioskService = new KioskService();
			await kioskService.contentVideoPost(formData);
			commit("changeKioskContentVideo", {
				selectedIds: [],
				formData: {
					file: null,
					destinationType: "schools",
					schoolType: "SMA",
				}
			})
			dispatch("fetchListContentVideo");
			return true;
		} catch (e) {
			console.log(e);

			if (isServerErrorOrNoInternet(e)) {
				Swal.fire("Error", SERVER_ERROR_MESSAGE, "error");
				return false;
			}

			Swal.fire("Error", e.error, "error");
			return false;
		}
	},

  async deleteContent({ commit }, payload) {
    const { contentId, type } = payload;
    // validasi data
    if (!contentId) {
      Message({
        type: "error",
        message: "ID tidak ditemukan",
      });
      return false;
    }

    try {
      const kioskService = new KioskService();
      await kioskService.deleteContent(contentId);
      if (type === "image") {
        commit("DELETE_ITEMS", contentId);
      } else {
        commit("DELETE_ITEMS_VIDEO", contentId);
      }
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      return true;
    } catch (e) {
      console.log(e);
      if (isServerErrorOrNoInternet(e)) {
        Swal.fire("Error", SERVER_ERROR_MESSAGE, "error");
        return false;
      }
      Swal.fire("Error", e.error, "error");
      return false;
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
