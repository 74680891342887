<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="#"
            class="font-weight-bold ml-1"
            target="_blank"
            >{{configApp.appName}}</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {mapState, mapMutations} from "vuex";
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState({
      configApp: (state) => state.configApp.data
    })
  },
  methods: {
    ...mapMutations("configApp", ["LOAD_CONFIG"])
  },
  mounted() {
    this.LOAD_CONFIG();
    //this.appName = process.env?.VUE_APP_NAME || "";
    //this.appCmsUrl = process.env?.VUE_APP_CMS_URL || "";
    //this.appCmsName = process.env?.VUE_APP_CMS_NAME || "";
  },
};
</script>
<style></style>
