class StrukturErrorService {
	static parseError(error) {
		console.log(error)
		if (error.response) {
			const responseError = {
				code: error.code,
				status: error.response.status,
				error: error.response.data.error || error.response.data.message,
				errorList: error.response.data.errors,
			};

			if (error.response.status === 307) {
				responseError.data = error.response.data;
			}

			return responseError;
		} else if (error.request) {
			console.log("Error with request:", error.request);
			return {
				status: 0,
				message: 'Error with request',
				error: 'Error with request',
				code: "ERR_NETWORK",
				errorList: null,
			};
		}
		return error;
	}
}

export default StrukturErrorService;
