import {ROLES} from "@/services/employeeService";

// Konstanta untuk peran yang sering diulang
const COMMON_ROLES = [ROLES.kadis, ROLES.kacabdin];
const ALL_ROLES = [...COMMON_ROLES, ROLES.sd, ROLES.smp, ROLES.sma, ROLES.smk, ROLES.slb];
const ADMIN_ROLES = [ROLES.kacabdin, ROLES.admin];
const PENGAWAS = [ROLES.sd, ROLES.smp, ROLES.sma, ROLES.smk, ROLES.slb];

export const ListMenu = () => {
  return [
    {
      icon: "ic_generate_token.svg",
      name: "token",
      label: "Generate Token",
      title: "Generate Token",
      isPathName: true,
      pathName: "generateToken",
      pathUrl: "/pemerintah/generate-token",
      roles: ALL_ROLES
    },
    {
      icon: "access.svg",
      name: "hakAkses",
      label: "Hak Akses",
      title: "Manajemen Kepegawaian",
      isPathName: true,
      pathName: "anggotaGoverment",
      pathUrl: "/pemerintah/anggota",
      roles: COMMON_ROLES,
      child: [
        {
          icon: "",
          name: "penggunaEmployee",
          label: "Employee",
          title: "Employee",
          isPathName: true,
          pathName: "anggotaEmployee",
          pathUrl: "/pemerintah/anggota/:id/employee",
          roles: COMMON_ROLES
        }
      ]
    },
    {
      icon: "kepegawaian.svg",
      name: "manajemenLcdBanner",
      label: "Banner Digital",
      title: "Banner Digital",
      isPathName: true,
      pathName: "bannerDigitalPage",
      pathUrl: "/pemerintah/banner-digital",
      roles: [ROLES.kacabdin, ROLES.kadis],
      child: []
    },
    {
      icon: "kepegawaian.svg",
      name: "manajemenPengawas",
      label: "Manajemen Pengawas",
      title: "Manajemen Pengawas",
      isPathName: true,
      pathName: "manajemenPengawas",
      pathUrl: "/pemerintah/manajemen-pengawas",
      roles: [ROLES.kacabdin],
      child: []
    },
    {
      icon: "kepegawaian.svg",
      name: "pengawasNaungan",
      label: "Sekolah",
      title: "Sekolah",
      isPathName: true,
      pathName: "pengawasNaungan",
      pathUrl: "/pemerintah/pengawas/naungan",
      roles: PENGAWAS,
      child: [
        {
          icon: "",
          name: "penggunaEmployee",
          label: "Employee",
          title: "Employee",
          isPathName: true,
          pathName: "anggotaEmployee",
          pathUrl: "/pemerintah/anggota/:id/employee",
          roles: PENGAWAS
        }
      ]
    },
    {
      icon: "surat-agenda.svg",
      name: "agenda",
      label: "Agenda Surat",
      title: "Agenda Surat",
      isPathName: true,
      pathName: "agendaSurat",
      pathUrl: "/pemerintah/agenda-surat",
      roles: ADMIN_ROLES,
      child: [
        {
          icon: "",
          name: "penggunaEmployee",
          label: "Employee",
          title: "Employee",
          isPathName: true,
          pathName: "anggotaEmployee",
          pathUrl: "/pemerintah/anggota/:id/employee",
          roles: ADMIN_ROLES
        }
      ]
    }
  ];
};

export function isRoleAllowed(menuRoles, activeRoles) {
  return menuRoles.some(role => activeRoles.includes(role));
}

