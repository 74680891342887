import Vue from "vue";
import Router from "vue-router";
import DashboardLayoutGoverment from "@/layout/Goverment/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import { ROLES } from "@/services/employeeService";
import { store } from "@/store";

//const COMMON_ROLES = [ROLES.kadis, ROLES.kacabdin];
//const ALL_ROLES = [...COMMON_ROLES, ROLES.sd, ROLES.smp, ROLES.sma, ROLES.smk, ROLES.slb];
//const ADMIN_ROLES = [ROLES.kacabdin, ROLES.admin];
const PENGAWAS = [ROLES.sd, ROLES.smp, ROLES.sma, ROLES.smk, ROLES.slb];

Vue.use(Router);

const Login = () => import(/* webpackChunkName: "login" */ "./views/Login.vue");
const GenerateToken = () =>
  import(
    /* webpackChunkName: "generate-token" */
    "./views/Goverment/GenerateToken/GenerateToken.vue"
  );
const ManajemenAnggota = () =>
  import(
    /* webpackChunkName: "manajemen-anggota" */
    "./views/Goverment/HakAkses/Anggota.vue"
  );
const ManajemenEmployee = () =>
  import(
    /* webpackChunkName: "manajemen-anggota" */
    "./views/Goverment/HakAkses/Employee/index.vue"
  );


const ManajemenAgendaSurat = () =>
  import(
    /* webpackChunkName: "manajemen-agenda-surat" */
    "./views/Goverment/AgendaSurat/index.vue"
    );

/*manajemen pengawas*/

const ManajemenPengawas = () =>
  import(
    /* webpackChunkName: "manajemen-pengawas" */
    "./views/Goverment/ManajemenPengawas/index.vue"
    );

const ManajemenPengawasDetail = () =>
  import(
    /* webpackChunkName: "manajemen-pengawas-detail" */
    "./views/Goverment/ManajemenPengawas/Detail/index.vue"
    );

const PengawasNaungan = () =>
  import(
    /* webpackChunkName: "pengawas-naungan" */
    "./views/Goverment/PengawasNaungan/index.vue"
    );

/*const PengawasPoinTukarPoin = () =>
  import(
    /!* webpackChunkName: "pengawas-poin-tukar-poin" *!/
    "./views/Goverment/PengawasPoin/TukarPoin/index.vue"
    );

const PengawasPoinRiwayat = () =>
  import(
    /!* webpackChunkName: "pengawas-poin-riwayat" *!/
    "./views/Goverment/PengawasPoin/Riwayat/index.vue"
    );*/

const Profile = () =>
  import(
    /* webpackChunkName: "manajemen-profile" */
    "./views/Goverment/Profile/index.vue"
    );

const NotFound = () =>
  import(
    /* webpackChunkName: "manajemen-anggota" */
    "./views/NotFound.vue"
  );

const BannerDigitalView = () =>
	import(
		/* webpackChunkName: "banner-digital" */
		"./views/Goverment/BannerDigital/MainView.vue"
		);
const BannerDigitalCreateView = () =>
	import(
		/* webpackChunkName: "banner-digital" */
		"./views/Goverment/BannerDigital/ModalForm"
		);
const VideoDigitalView = () =>
	import(
		/* webpackChunkName: "video-digital" */
		"./views/Goverment/BannerDigital/Video/MainView.vue"
		);
const VideoDigitalCreateView = () =>
	import(
		/* webpackChunkName: "video-digital" */
		"./views/Goverment/BannerDigital/Video/ModalForm"
		);

const router = new Router({
  linkExactActiveClass: "actsudo sive",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "login",
      component: AuthLayout,
      children: [
        {
          path: "/login",
          name: "login",
          component: Login,
          meta: {
            allowedRole: ["all"]
          }
        }
      ]
    },
    {
      path: "/pemerintah",
      redirect: "pemerintah/generate-token",
      component: DashboardLayoutGoverment,
      meta: {
        requiresAuth: true,
        schoolAuth: false,
        govAuth: true
      },
      children: [
        {
          path: "profile",
          name: "profile",
          component: Profile,
          meta: {
            title: "Detail Profile",
            allowedRole: [
              ROLES.kadis,
              ROLES.kacabdin,
              ROLES.admin,
              ROLES.sd,
              ROLES.smp,
              ROLES.sma,
              ROLES.smk
            ]
          },
          beforeEnter: requirePermission
        },
        {
          path: "generate-token",
          name: "generateToken",
          component: GenerateToken,
          meta: {
            title: "Generate Token",
            allowedRole: [
              ROLES.kadis,
              ROLES.kacabdin,
              ROLES.sd,
              ROLES.smp,
              ROLES.sma,
              ROLES.smk
            ]
          },
          beforeEnter: requirePermission
        },
        {
          path: "banner-digital",
          name: "bannerDigitalPage",
          component: BannerDigitalView,
          meta: {
            title: "Banner Digital",
            allowedRole: [
              ROLES.kadis,
              ROLES.kacabdin,
            ]
          },
          beforeEnter: requirePermission
        },
        {
          path: "banner-digital/add",
          name: "bannerDigitalAddPage",
          component: BannerDigitalCreateView,
          meta: {
            title: "Banner Digital",
            allowedRole: [
              ROLES.kadis,
              ROLES.kacabdin,
            ]
          },
          beforeEnter: requirePermission
        },
        {
          path: "video-digital",
          name: "videoDigitalPage",
          component: VideoDigitalView,
          meta: {
            title: "Video Digital",
            allowedRole: [
              ROLES.kadis,
              ROLES.kacabdin,
            ]
          },
          beforeEnter: requirePermission
        },
        {
          path: "video-digital/add",
          name: "videoDigitalAddPage",
          component: VideoDigitalCreateView,
          meta: {
            title: "Video Digital",
            allowedRole: [
              ROLES.kadis,
              ROLES.kacabdin,
            ]
          },
          beforeEnter: requirePermission
        },
        {
          path: "school-digital",
          name: "schoolDigitalPage",
          component: VideoDigitalCreateView,
          meta: {
            title: "Daftar Sekolah Banner Digital",
            allowedRole: [
              ROLES.kadis,
              ROLES.kacabdin,
            ]
          },
          beforeEnter: requirePermission
        },

        {
          path: "anggota",
          name: "anggotaGoverment",
          component: ManajemenAnggota,
          meta: {
            title: "Hak Akses Pengguna Tingkat Kota/Kabupaten",
            allowedRole: [ROLES.kadis, ROLES.kacabdin]
          },
          beforeEnter: requirePermission
        },
        {
          path: "anggota/:id/employee",
          name: "anggotaEmployee",
          component: ManajemenEmployee,
          meta: {
            title: "Pengguna Tingkat Kota/Kabupaten",
            allowedRole: [ROLES.kadis, ROLES.kacabdin]
          },
          beforeEnter: requirePermission
        },
        {
          path: "agenda-surat",
          name: "agendaSurat",
          component: ManajemenAgendaSurat,
          meta: {
            title: "Pengisian Agenda Surat",
            allowedRole: [ROLES.kacabdin, ROLES.admin]
          },
          beforeEnter: requirePermission
        },
        {
          path: "manajemen-pengawas",
          name: "manajemenPengawas",
          component: ManajemenPengawas,
          meta: {
            title: "Manajemen Pengawas",
            allowedRole: [ROLES.kacabdin]
          },
          beforeEnter: requirePermission
        },
        {
          path: "manajemen-pengawas/:employeeId",
          name: "manajemenPengawasDetail",
          component: ManajemenPengawasDetail,
          meta: {
            title: "Detail Pengawas",
            allowedRole: [ROLES.kacabdin]
          },
          beforeEnter: requirePermission
        },
        {
          path: "pengawas/naungan",
          name: "pengawasNaungan",
          component: PengawasNaungan,
          meta: {
            title: "Pengawas Binaan Sekolah",
            allowedRole: PENGAWAS
          },
          beforeEnter: requirePermission
        },
        /*{
          path: "pengawas/poin",
          name: "pengawasPoin",
          redirect: {name: "pengawasPoinRiwayat"},
          meta: {
            title: "Pengawas Poin",
            allowedRole: PENGAWAS
          },
          beforeEnter: requirePermission
        },
        {
          path: "pengawas/poin/riwayat",
          name: "pengawasPoinRiwayat",
          component: PengawasPoinRiwayat,
          meta: {
            title: "Riwayat Transaksi",
            allowedRole: PENGAWAS
          },
          beforeEnter: requirePermission
        },
        {
          path: "pengawas/poin/tukar-poin",
          name: "pengawasPoinTukarPoin",
          component: PengawasPoinTukarPoin,
          meta: {
            title: "Tukar Poin",
            allowedRole: PENGAWAS
          },
          beforeEnter: requirePermission
        },*/
      ]
    },

    // Rute untuk halaman "Page Not Found" yang akan menangkap URL yang tidak cocok dengan rute apa pun di atas.
    {
      path: "*",
      component: NotFound
    }
  ]
});

function isUserAuthenticated() {
  const localDataVuex = JSON.parse(localStorage.getItem("vuex"));

  return !!(
    localDataVuex &&
    localDataVuex.token !== "" &&
    localDataVuex.user.roles
  );
}

function getUserRoles() {
  const localDataVuex = JSON.parse(localStorage.getItem("vuex"));
  if (localDataVuex && localDataVuex.user && localDataVuex.user.roles) {
    return localDataVuex.user.roles.map(role => role.name);
  }
  return [];
}

function requirePermission(to, from, next) {
  if (!isUserAuthenticated()) {
    next({ path: "/login" });
    return;
  }

  const userRoles = getUserRoles();

  const allowedRoles = to.meta.allowedRole;

  // Check if the user's role is among the allowed roles for the route
  const hasPermission =
    allowedRoles.includes("all") ||
    userRoles.some(role => allowedRoles.includes(role));

  if (!hasPermission && to.name !== "generateToken") {
    next({ name: "generateToken" });
    return;
  }

  next(); // Continue with navigation
}

router.beforeEach((to, from, next) => {
  // Use isUserAuthenticated for checking user authentication
  const isAuthenticated = isUserAuthenticated();
  const localDataVuex = JSON.parse(localStorage.getItem("vuex"));

  // Pastikan localDataVuex dan localDataVuex.user.roles ada sebelum memanggil fungsi `some`
  const isKacabdin =
    localDataVuex &&
    localDataVuex.user.roles &&
    localDataVuex.user.roles.some(role => role.name === ROLES.kacabdin);

  const isAdmin =
    localDataVuex &&
    localDataVuex.user.roles &&
    localDataVuex.user.roles.some(role => role.name === ROLES.admin);

  // Pengecekan khusus untuk komponen Anggota.vue
  if (to.name === "generateToken" && isAuthenticated && isAdmin) {
    next({name: "agendaSurat"});
    return;
  }

  // Pengecekan khusus untuk komponen Anggota.vue
  if (to.name === "anggotaGoverment" && isAuthenticated && isKacabdin) {
    const govermentId = store.getters.govermentIdGetters;
    next({
      name: "anggotaEmployee",
      params: { id: govermentId }
    });
    return;
  }

  // Jika rute memerlukan autentikasi dan pengguna belum terotentikasi, alihkan ke halaman login
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ path: "/login" });
  } else if (to.path === "/login" && isAdmin) {
    next({ name: "agendaSurat" });
  } else if (to.path === "/login" && isAuthenticated) {
    next({ name: "generateToken" });
  } else {
    next();
  }
});

export default router;
